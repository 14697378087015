<template>
<div>
 
  <div>
    
    <h1 class="mb-0">Sign in</h1>
      <error v-if="error" :error='error'/>
     <form class="mt-4" @submit.prevent="handleSubmit">
       
      <div class="form-group">
            <input type="email" v-model="user.email" class="form-control mb-0" id="email" placeholder="Enter email">
      </div>
      <div class="form-group mt-4 sm-mt-1">
           <input type="password" v-model="user.password" class="form-control mb-0" id="password" placeholder="Password">
      </div>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block">
          <input type="checkbox" class="custom-control-input" id="customCheck1">
          <label class="custom-control-label" for="customCheck1">Remember Me</label>
        </div>
        <router-link :to="{ name: 'auth1.password-reset1' }" class="float-right sm-float-none">Forgot password?</router-link>
        <button type="submit" class="btn btn-bluish mt-4">Sign in</button>
      </div>
      </form>
  </div>
  </div>
</template>


<script>
import axios from '../../../components/axios'
import Error from '../../../components/socialvue/error/Error'
// import Error from '../../../components/socialvue/error/Error.vue';
export default {
  name: "Signin",
  components:{
    Error
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
        
      },
      error:"",
    };
  },
  mounted() {
    // var parent = this;
    
  },

  methods: {
     async handleSubmit(){
        const config = {
          method: "post",
          url: "signin",
          data:this.user,
        };
        var parent = this;
         await axios(config).then(res => {

          localStorage.setItem('token',res.data.token)
          localStorage.setItem('user',JSON.stringify(res.data.user)) 

          
          

          if (localStorage.getItem('token') != null){
            // this.$router.replace('/')
            // this.$store.commit('setAuthentication',true)
            if(res.data.user.is_profile_complete === 0){
              this.$router.replace('/sign-profile-detail')
            }else{
              this.$router.replace('/')
            }
             
          }
          // this.$root.$emit('user', res.data.user);
          
        }).catch(err => {
            this.error = err.response.data.message;
        })
       
        
    
    

      
     
    },



    
    // @click="$router.push({name: 'social.list'})"
    // login: function() {
    //  var config = {
    //   method: "post",
    //   url: "http://wfdapi.hyathi.com/api/signin",
    //   data:this.user,
    //   //  headers: {
    //   //   'Content-Type': 'application/json',
    //   // },
    // };

    
    
  },
};
</script>
